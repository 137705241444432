@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Arial", sans-serif;
}

/* Define scrollbar styles */
body::-webkit-scrollbar {
  width: 9px; /* Width of the scrollbar */
}

/* Track */
body::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the scrollbar track */
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background: #000; /* Color of the scrollbar thumb */
  border-radius: 6px;
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
  background: #000; /* Color of the scrollbar thumb on hover */
}

.sidebar::-webkit-scrollbar {
  width: 9px; /* Width of the scrollbar */
}

.sidebar {
  -ms-overflow-style: none; /* Hides the scrollbar in Internet Explorer and Edge */
  scrollbar-width: none; /* Hides the scrollbar in Firefox */
}

.react-datepicker__input-container {
  position: static !important;
  display: inline-block;
  width: 100%;
}

/* In your CSS file or inline styles */
.datepicker-wrapper {
  z-index: 20 !important; /* This makes sure it stays on top when opened */
}

.react-datepicker {
  z-index: 20 !important; /* This makes sure it stays on top when opened */
}

.table-container::-webkit-scrollbar {
  display: none; /* Width of the scrollbar */
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-25px);
  }
  100% {
    transform: translateY(0);
  }
}
